var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"blog-edit-wrapper"},[_c('div',{staticClass:"text-center"},[_c('h4',{staticClass:"mt-5"},[_vm._v(" Change Feature Course "),_c('feather-icon',{attrs:{"icon":"Edit2Icon"}})],1)]),_c('validation-observer',{ref:"addCourseFormvalidate"},[_c('b-form',{staticClass:"mt-2",staticStyle:{"width":"60%"},on:{"submit":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Course ","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.coursesList,"reduce":function (val) { return val.id; }},model:{value:(_vm.addCourseForm.course),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "course", $$v)},expression:"addCourseForm.course"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Select  Instructor","label-for":"blog-edit-course"}},[_c('validation-provider',{attrs:{"name":"Courses","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"blog-edit-category","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.instructorList,"reduce":function (val) { return val.id; }},model:{value:(_vm.addCourseForm.instructor),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "instructor", $$v)},expression:"addCourseForm.instructor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Location","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"blog-edit-title"},model:{value:(_vm.addCourseForm.location),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "location", $$v)},expression:"addCourseForm.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"border rounded p-2"},[_c('h4',{staticClass:"mb-1"},[_vm._v("Logo")]),_c('b-media',{staticClass:"flex-column flex-md-row",attrs:{"no-body":"","vertical-align":"center"}},[_c('b-media-aside',[_c('b-img',{ref:"refPreviewE2",staticClass:"rounded mr-2 mb-1 mb-md-0",attrs:{"src":_vm.Tabimage,"height":"110","width":"170"}})],1),_c('b-media-body',[_c('b-card-text',{staticClass:"my-50"}),_c('div',{staticClass:"d-inline-block"},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":"Tab Image ","label-for":"blog-edit-title"}},[_c('validation-provider',{attrs:{"name":"Tab Image ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{ref:"refInputE2",attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choose file"},on:{"input":_vm.inputImageRendererTab},model:{value:(_vm.addCourseForm.logo),callback:function ($$v) {_vm.$set(_vm.addCourseForm, "logo", $$v)},expression:"addCourseForm.logo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)])],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Save Changes ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }